@tailwind base;
@tailwind components;
@tailwind utilities;

.katex {
  margin: 0;
  border: none;
  padding: 0;
}

.katex-display {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
}
